<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{
              name: 'DashboardHome',
            }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: 'OrganizationHome',
              }"
            >
              組織資料設定
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: 'ModuleList',
              }"
            >
              模組管理
            </b-breadcrumb-item>
            <b-breadcrumb-item active>
              模組設定
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row mb-2 align-items-end">
        <div class="col-8 d-flex items-center">
          <h4 class="font-weight-bold">{{organization.name}} 組織設定</h4>
        </div>
      </div>

      <b-overlay :show="isLoading" rounded opacity="0.6" spinner-small spinner-variant="primary">
        <b-form-group
          label-cols="12"
          label-cols-lg="2"
          label-size="sm"
          label="模組名稱"
          >
          <b-form-input
            class="mb-2 mr-sm-2 mb-sm-0"
            type="text"
            v-model="name"
            readonly
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label-cols="12"
          label-cols-lg="2"
          label-size="sm"
          label="模組代號"
          >
          <b-form-input
            class="mb-2 mr-sm-2 mb-sm-0"
            type="text"
            v-model="code"
            readonly
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label-cols="12"
          label-cols-lg="2"
          label-size="m"
          :label="forms[0] && forms[0].category"
          label-class="font-weight-bold"
          class="group pt-4"
          v-for="(forms, i) in formGroups" :key="i"
        >
          <section class="row mb-8">
            <div class="col-12 col-xl-8">
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                :description="f.description || ''"
                :label="f.name || ''"
                :label-for="f.key || ''" v-for="f in forms" :key="f.id"
                >
                <b-form-checkbox
                  v-if="f.type == 'boolean'"
                  :id="f.key"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="form[f.key]"
                  :name="f.key"
                  :value="true"
                  :unchecked-value="false"
                ></b-form-checkbox>
                <color-picker :id="f.key" v-model="form[f.key]" v-else-if="f.type == 'color'"></color-picker>
                <b-form-checkbox-group
                  v-else-if="f.type == 'array' && f.config && f.config['ui-data'] && f.config['ui-data']['type'] == 'array-checkbox'"
                  :id="f.key"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="form[f.key]"
                  :options="f.config['ui-data']['options']"
                  :name="f.key"
                ></b-form-checkbox-group>
                <editor
                  v-else-if="f.type == 'json' || f.type == 'array'"
                  :id="f.key"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="form[f.key]"
                  width="100%"
                  height="200px"
                  @init="editorInit"
                  lang="json"
                  theme="chrome"
                >
                </editor>
                <editor
                  v-else-if="f.type == 'html'"
                  :id="f.key"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="form[f.key]"
                  width="100%"
                  height="200px"
                  @init="editorInit"
                  lang="html"
                  theme="chrome"
                >
                </editor>
                <b-form-input
                  v-else-if="f.type == 'number'"
                  :id="f.key"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="number"
                  v-model="form[f.key]"
                ></b-form-input>
                <button
                  v-else-if="f.type == 'file'"
                  class="btn btn-link px-2 text-dark align-self-start upload-button"
                >
                  <b-form-file
                    @change="upload($event, f.key)"
                  />
                    <a v-if="!!form[f.key]" @click="form[f.key] = null" class="btn-sm btn-outline-danger"><i class="fa fa-trash-o"></i></a>
                    <a :href="form[f.key]" target="_blank"><small> 目前路徑：{{form[f.key] || '未上傳'}} </small></a>
                </button>
                <b-form-select
                  v-else-if="f.type == 'string' && f.config && f.config['ui-data'] && f.config['ui-data']['type'] == 'select'"
                  :options="(f.config['ui-data'] && f.config['ui-data']['options']) || {}"
                  :id="form.key"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="form[f.key]"
                  :state="v$.form[f.key] && v$.form[f.key].$error ? false : null"
                ></b-form-select>
                <b-form-radio-group
                  v-else-if="f.type == 'string' && f.config && f.config['ui-data'] && f.config['ui-data']['type'] == 'radio'"
                  :options="(f.config['ui-data'] && f.config['ui-data']['options']) || {}"
                  :id="form.key"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="form[f.key]"
                  :state="v$.form[f.key] && v$.form[f.key].$error ? false : null"
                ></b-form-radio-group>
                <b-form-input
                  v-else
                  :id="form.key"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="text"
                  v-model="form[f.key]"
                  :state="v$.form[f.key] && v$.form[f.key].$error ? false : null"
                ></b-form-input>

                <b-form-invalid-feedback
                 :state="v$.form[f.key] && v$.form[f.key].$error ? false : null"
                 v-for="(error, i) in v$.form[f.key].$errors" :key="i"
                >
                  {{error.$message || '欄位為必填'}}
                </b-form-invalid-feedback>
              </b-form-group>

            </div>
          </section>
        </b-form-group>
      </b-overlay>

      <div class="d-flex justify-content-center">
        <b-button class="mr-4" variant="outline-danger" @click="handleCancel">取消</b-button>
        <b-overlay
          :show="isSubmmiting"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <b-button class="mr-2" variant="success" @click="handleSubmit(false)" :disabled="isLoading">
            儲存
          </b-button>
          <b-button variant="success" @click="handleSubmit" :disabled="isLoading">
            儲存後關閉
          </b-button>
        </b-overlay>
      </div>
    </b-card>
  </div>
</template>

<style scoped lang="scss">
.group+.group {
  border-top: 1px solid #cdd6dc !important;
}
</style>
<script>
import { reactive } from "@vue/composition-api";
import { mapState, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, numeric, helpers } from "@vuelidate/validators";
import moduleApi from "../../../apis/module";
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";
import editor from 'vue2-ace-editor'
import fileMixin from "@/mixins/uploadFile";
import _ from 'lodash'
import ColorPicker from '@/components/ColorPicker'

const mustBeJson = (value) => {
  try {
    if (typeof value === 'string')
      JSON.parse(value)
    return {$valid: true}
  } catch (e) {
    return {
      $valid: false,
      message: '必須為 JSON 格式'
    }
  }
};

const mustBeArray = (value) => {
  try {
    if ((typeof value === 'string' &&  Array.isArray(JSON.parse(value))) || Array.isArray(value)) {
      return {$valid: true}
    }
    return {
      $valid: false,
      message: '必須為 Array 格式'
    }
  } catch (e) {
    return {
      $valid: false,
      message: '必須為 Array 格式'
    }
  }
};

export default {
  mixins: [fileMixin],
  components: {editor, 'color-picker': ColorPicker},
  validationConfig: {
    $lazy: true,
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    /*
     *  由於欄位是個動態 array ，因此 vuelidate 幾乎觸發不了驗證
     * ，因此編輯時暫時將
     *  forms = [{key: 'k', value: 'v'}]
     *  轉為form = {k:v}
     *  最後 submit 再轉換回來
    */
    return {
      code: null,
      name: null,
      color: '',
      isLoading: false,
      isSubmmiting: false,
      forms: [],
      form: reactive({}),
      formGroups: {},
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    ...mapGetters({
      getModuleConfig: "dashboardModule/getConfig",
    }),
    orgId() {
      return this.$route.params.orgId;
    },
    moduleId() {
      return this.$route.params.moduleId;
    },
  },
  validations() {
    let rules = {
      form: {},
    }
    this.forms.forEach(form => {
      rules.form[form.key] = {
      }
      if (form.config && !!form.config.required) {
        rules.form[form.key] = {
          required: helpers.withMessage('此欄位為必填', required)
        }
      }
      switch (form.type) {
        case 'number':
          rules.form[form.key]['numeric'] = helpers.withMessage('此欄位必須為數字', numeric)
          break
        case 'json':
          rules.form[form.key]['mustBeJson'] = helpers.withMessage('此欄位必須為 JSON 格式', mustBeJson)
          break
        case 'array':
          rules.form[form.key]['mustBeArray'] = helpers.withMessage('此欄位必須為 Array 格式', mustBeArray)
          break
      }
    })
    return rules
  },
  mounted() {
    if (!this.checkPermission([consts.MERCHANT_MODIFY])) {
      this.$swal
        .fire({
          type: "error",
          text: "你沒有權限訪問此頁面",
          confirmButtonColor: "#d33"
        })
        .then(() => {
          this.$router.push({ name: "ModuleList" });
        });
    } else if (this.moduleId) {
      this.fetchModule();
    }
  },
  methods: {
    editorInit () {
        require('brace/ext/language_tools') //language extension prerequsite...
        require('brace/mode/json')
        require('brace/mode/html')
        require('brace/theme/chrome')
    },
    async fetchModule() {
      try {
        this.isLoading = true;
        const { data } = await moduleApi.getModuleConfigMap(this.orgId, this.moduleId);
        this.name = data.meta.name
        this.code = data.meta.code
        this.forms  = data.data.map(form => {
          switch (form.type) {
            case 'json':
            case 'array':
              if (form.type == 'array' &&
                form.config &&
                form.config['ui-data'] &&
                form.config['ui-data']['type'] == 'array-checkbox') {
                  form.config['ui-data']['options'] = form.config['ui-data']['options'].sort((a, b) => a.order - b.order)
                  form.value = form.value || []
                  break
              }
              form.value = JSON.stringify(form.value, null, 2) || ''
              break
            case'string':
              form.value = form.value || ''
              break
          }
          this.$set(this.form, form.key, form.value)
          return form
        });
        this.formGroups = _(this.forms)
          .groupBy(form => form.category)
          .map(group => _(group).orderBy('order').value())
          .sortBy((g) => {
              return g && g[0].category
          })
          .value()
      } catch (error) {
        console.error("error", error);
        console.log("");
      } finally {
        this.isLoading = false;
      }
    },
    async upload(e, key) {
      this.isLoading = true
      let url = ''
      if (key.includes('sample_file') && key.includes('xls.url')) {
        const fileName = this.form[key.replace('url', 'file_name')]
        if(fileName) {
          url =  await this.uploadFile(e.target.files[0], fileName)
        } else {
          url =  await this.uploadFile(e.target.files[0])
        }
      } else {
        url =  await this.uploadFile(e.target.files[0])
      }
      this.form[key] = url
      this.isLoading = false
      return url
    },
    async handleSubmit(redirect = true) {
      const result = await this.v$.$validate();
      if (!result) return;
      this.handleUpdate(redirect);

    },
    async handleUpdate(redirect = true) {
      try {
        this.isSubmmiting = true;
        let forms  = this.forms.map(form => {
          form.value = this.form[form.key]
          if (form.type == 'json' || form.type == 'array') {
              if (form.type == 'array' &&
                form.config &&
                form.config['ui-data'] &&
                form.config['ui-data']['type'] == 'array-checkbox') {
                return form
              }
            return {...form, value: JSON.parse(form.value)}
          }
          return form
        });
        let module = {
            data: forms
        }
        await moduleApi.updateModuleConfigMap(this.orgId, this.moduleId, module);
        this.showSuccessPopup(redirect);
      } catch (error) {
        console.log(error)
        const { status, data } = error.response;
        if (status && status === 422 && data.message) {
          const html = Object.values(data.message)
            .map((m) => m[0])
            .join("<br/>");
          this.$swal.fire({
            type: "error",
            html,
          });
        }
      }
      this.isSubmmiting = false;
    },
    handleCancel() {
      this.$router.push({ name: "ModuleList" });
    },
    showSuccessPopup(redirect = true) {
      this.$swal
        .fire({
          type: "success",
          text: "修改成功",
        })
        .then(() => {
          if (redirect) {
            this.$router.push({ name: "ModuleList" });
          }
        });
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    }
  },
};
</script>
